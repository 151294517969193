<!-- @format -->

<template>
  <slideout
    @closing="onClosing"
    v-model="onShow"
    title="Select Course Batch Schedule"
    size="0"
  >
    <div class="sm:p-2 md:p-4 lg:p-8 lg:max-w-2xl" v-if="course">
      <CourseInfoBar :course="course"></CourseInfoBar>
      <!--       <div class="mt-4 p-4 bg-white/80 rounded-lg flex w-full space-x-4">
        <div class="flex items-center mr-10">
          <div
            class="w-10 h-10 flex items-center justify-center bg-sky-100 border border-sky-200 text-sky-600 rounded-full text-xl"
          >
            <fa-icon :icon="['fa', 'school']"></fa-icon>
          </div>
          <div class="flex-1 ml-4">
            <div
              class="font-light text-neutral-300 border-b border-dashed border-neutral-200"
            >
              Training Provider
            </div>
            <div class="text-lg font-semibold text-sky-600">{{ course.client.name }}</div>
          </div>
        </div>
        <div class="flex items-center">
          <div
            class="w-10 h-10 flex items-center justify-center bg-sky-100 border border-sky-200 text-sky-600 rounded-full text-xl"
          >
            <fa-icon :icon="['fa', 'tag']"></fa-icon>
          </div>
          <div class="flex-1 ml-4 mr-10">
            <div
              class="font-light text-neutral-300 border-b border-dashed border-neutral-200"
            >
              SSG Subsidy
            </div>
            <div class="text-lg font-semibold text-sky-600">Available</div>
          </div>
        </div>

        <div class="flex items-center mr-10">
          <div class="flex-1 ml-4 mr-10">
            <div class="text-lg font-semibold text-sky-600">
              <FlatMiniButton backgroundColor="bg-lime-500 hover:bg-lime-600">
                <div class="py-1 px-3 text-md">Check Subsidy Amount</div>
              </FlatMiniButton>
            </div>
          </div>
        </div>
      </div> -->

      <div
        class="p-4 text-center text-sky-600 font-semibold text-xl flex w-full items-center justify-center"
      >
        <div class="border-b border-sky-600">Choose Course Run Batch</div>
      </div>
      <div
        class="p-1 bg-white/50 border border-white grid grid-cols-1 gap-2"
        v-if="runlist.length > 0"
      >
        <div
          v-for="i in runlist"
          :key="i.id"
          class="p-2 bg-white border border-sky-200 rounded-lg flex"
        >
          <div class="">
            <div class="text-purple-400 text-center uppercase">Batch</div>
            <div
              class="text-xl h-8 border border-sky-200 bg-sky-50 text-center p-2 leading-none font-bold text-sky-600"
            >
              #{{ i.batch_number }}
            </div>
            <!--             <div class="text-neutral-400 mt-1" :class="i.course_fee == null ? 'hidden' :''">Course Run Fee</div>
              <div class="text-base text-lime-500">{{ course.default_currency }} {{i.course_fee}}</div> -->
          </div>
          <div class="flex-1 ml-2 flex items-center">
            <div>
              <div class="text-neutral-400">Training Schedule</div>
              <div class="md:text-lg text-neutral-600 font-bold">
                {{ i.course_start }} to {{ i.course_end }}
              </div>
            </div>
          </div>
          <div
            v-if="course.has_taken"
            class="p-2 bg-lime-500 rounded-md hover:bg-lime-600 cursor-pointer flex items-center flex-grow-0"
            @click="recommend"
          >
            <div class="uppercase flex-grow-0 text-white font-bold text-center">
              Recommend
            </div>
          </div>
          <div
            v-else
            class="p-2 bg-lime-500 rounded-md hover:bg-lime-600 cursor-pointer flex items-center flex-grow-0"
            @click="selectCourseRun(i)"
          >
            <div class="uppercase flex-grow-0 text-white font-bold text-center">
              Register
            </div>
          </div>
        </div>
      </div>
      <div
        class="mt-8 px-20 p-4 rounded-2xl bg-white/80 border-2 border-neutral-300 text-center text-lime-700 text-lg"
      >
        <div class="">
          <span class="text-neutral-500" v-if="runlist.length < 1"
            >We are sorry, there's no active course run batch at the moment.</span
          >
          <span v-else>No batch matching your schedule? </span>
          Don't worry you can register interest now and we will notify you when new batch
          is available
        </div>
        <div class="flex w-full justify-center pt-2">
          <FlatMiniButton backgroundColor="bg-neutral-300"
            ><div class="py-1 px-4 text-md text-neutral-500" @click="onInterest">
              Register Interest
            </div></FlatMiniButton
          >
        </div>
      </div>
    </div>
  </slideout>
</template>
<script>
import { defineComponent } from "vue";
import CourseInfoBar from "@/components/CourseInfoBar.vue";
import FlatMiniButton from '@/components/FlatMiniButton.vue';
export default defineComponent({
  props: {
    showOn: {
      type: Boolean,
      default: false,
    },
    course: Object,
  },
  data() {
    return {};
  },
  methods: {
    async recommend() {
      this.onShow = false;
      let ask = await this.$swal.fire({
        title: "Recommend Course",
        text: "Please fill in email address",
        showCancelButton: true,
        confirmButtonText: "Send",
        input: "text",
      });
      if (ask.isConfirmed) {
        this.$swal.fire("This feature is coming soon");
      }
    },
    selectCourseRun(courserun) {
      this.onShow = false;
      this.$emit("onSelectRun", courserun);
    },
    onClosing() {},
    async onInterest() {
/*       this.onShow = false;
      this.$swal.fire({
        title: "Register Your Interest",
        html: `
                <label>Your Email:</label><br>
                <input id="email" class="swal2-input" placeholder="Email">
            `,
        input: "textarea",
        inputLabel: "Your Message",
        inputAttributes: {
          placeholder: "Your Message",
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Submit",
        showLoaderOnConfirm: true,
        preConfirm: (text) => {
          let email = document.getElementById("email").value;
          return this.$inertia.post(
            route("cart.addinterest", {
              email: email,
              data: text,
              courseid: this.course.id,
            })
          );
        },
      }); */
    },
  },
  computed: {
    runlist() {
      if (this.course) {
        return this.course.active_runs ?? [];
      }
      return [];
    },
    onShow: {
      get() {
        return this.showOn;
      },
      set(v) {
        return this.$emit("update:showOn", v);
      },
    },
  },
  components: { CourseInfoBar, FlatMiniButton },
});
</script>
<style lang=""></style>
